:root {
    --white: #ffffff;
    --black: #000000;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --primary: #A8DF8E;
    --secondary: #A8DF8E;
    --tertiary: #A8DF8E;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --text-primary: #A8DF8E;
    --text-secondary: #A8DF8E;
    --text-tertiary: #A8DF8E;
    --bg-primary: #A8DF8E;
    --bg-secondary: #A8DF8E;
    --bg-tertiary: #A8DF8E;    
}